import React, { useEffect, useState } from "react";
import { Button, Text, Image, Flex, HStack } from "@chakra-ui/react";
import agent from "../../app/api/agent";
import useQuery from "../../app/common/util/hooks";
import { useStore } from "../../app/stores/store";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import CheckImage from "assets/images/onboarding/check.webp";
import { Alert } from "assets/icons/unauthenticated";
import { history } from "../..";
import { useTranslation } from "react-i18next";

export default function VerifyWhitelistedAddresses() {
    const {
        profileStore: { profile },
    } = useStore();
    const email = useQuery().get("email") as string;
    const token = useQuery().get("token") as string;
    const address = useQuery().get("address") as string;
    const { t } = useTranslation();

    const Status = {
        Verifying: "Verifying",
        Failed: "Failed",
        Success: "Success",
    };

    const [status, setStatus] = useState(Status.Verifying);
    const [notification, setNotification] = React.useState<any>();

    // function handleConfirmEmailResend() {

    //     agent.WhitelistedAddresses.verify(email).then(() => {
    //         toast.success('Verification email resent - please check your email');
    //     }).catch(error => console.log(error));
    // }

    useEffect(() => {
        agent.WhitelistedAddresses.verify({ token: token, email: email, address: address })
            .then(() => {
                setStatus(Status.Success);
            })
            .catch(() => {
                setStatus(Status.Failed);
            });
    }, [Status.Failed, Status.Success, token, email]);

    function getBody() {
        switch (status) {
            case Status.Verifying:
                return (
                    <Text mb="10" size="paragraph_semibold" color="gray.400" textAlign="center">
                        {t("verifying")}
                    </Text>
                );
            case Status.Failed:
                return (
                    <>
                        <HStack mb="10" textAlign="center">
                            <Flex minW="44px" minH="44px" w="0" h="0" borderRadius="full" bg="black" align="center" justify="center">
                                <Alert />
                            </Flex>
                            <Text mb="10" size="paragraph_semibold" color="gray.400" textAlign="center">
                                {t("verification.failed")}
                            </Text>
                        </HStack>

                        <Button
                            variant="primary"
                            // onClick={handleConfirmEmailResend}
                        >
                            {t("resend.email")}
                        </Button>
                    </>
                );
            case Status.Success:
                return (
                    <>
                        <Image mx="auto" w="180px" h="160px" mb="6" src={CheckImage} />
                        <Text mb="10" size="paragraph_semibold" color="gray.400" textAlign="center">
                            {t("address.confirmed")}
                        </Text>
                        <Button
                            variant="primary"
                            onClick={() => history.push("/funding")}
                        >
                            {t("navigation.funding")}
                        </Button>
                    </>
                );
        }
    }

    return (
        <AuthenticationTemplate notification={notification} headingText="Email verification" mb="3">
            {getBody()}
        </AuthenticationTemplate>
       
    );
}
