import * as React from "react";
import { Flex, FlexProps, Heading, Text, Divider, Spinner, Grid, Box, Button } from "@chakra-ui/react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { getRecordNo, PagingParams } from "app/models/pagination";
import { useTranslation } from "react-i18next";

export default observer(function ProfileLoginInfos(props: FlexProps) {
    
    const {
        userStore: { isAuthorized },
        profileStore: {
            loadLoginInfos,
            loginInfos,
            loading,
            setLoginInfoPagingParams,
            loginInfoPagingParams,
            loginInfoPagination,
        },
    } = useStore();
    const [loadingNext, setLoadingNext] = React.useState(false);
    const { t } = useTranslation();

    // useEffect(() => {
    //     console.log("ProfileLoginInfos");
    //     setLoginInfoPagingParams(new PagingParams(1, 3));
    //     if (loginInfos.length < 1) {
    //         loadLoginInfos(isAuthorized);
    //     }
    //     return () => {
    //         setLoginInfoFilterParams(new LoginInfoFilterParams());
    //     };
    // }, [isAuthorized, loadLoginInfos, setLoginInfoFilterParams]);

    function handleGetNext() {
        setLoadingNext(true);
        setLoginInfoPagingParams(new PagingParams(loginInfoPagination!.currentPage + 1, loginInfoPagingParams.pageSize));
        loadLoginInfos(isAuthorized).then(() => setLoadingNext(false));
    }

    return (
        <Flex color="white" direction="column" {...props}>
            <Flex justify="space-between" align="center" w="100%" mb="6">
                <Heading size="h4_bold">{t("last.logins")} </Heading>
            </Flex>
            <Flex
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                py={{ base: "6", sm: "8" }}
                px={{ base: "4", sm: "8" }}
            >
                <Flex direction="column" width="100%">
                    {loading && !loadingNext ? (
                        // <Skeleton isLoaded={!loading} height='20px'/>
                        <Spinner alignContent={"center"} color="yellow.500" size="xl" />
                    ) : (
                        loginInfos &&
                        loginInfos.length > 0 && (
                            <>
                                {loginInfos.map((login, i) => (
                                    <Flex key={i} direction="column" width="100%">
                                        <Grid
                                            templateColumns="repeat(2, 1fr)"
                                            mb={i < loginInfos.length - 1 ? "4" : "0"}
                                            gap="4"
                                            align="center"
                                            width="100%"
                                        >
                                            <Box>
                                                <Text
                                                    size="paragraph_normal"
                                                    // overflow="hidden"
                                                    // whiteSpace="nowrap"
                                                    // textOverflow="ellipsis"
                                                    textAlign="start"
                                                >
                                                    {format(login.date, "dd MMM yyyy h:mm aa")}
                                                </Text>
                                            </Box>
                                            <Box>
                                                <a href={`https://iplocation.io/ip/${login.ipAddress}`} target="_blank">
                                                    <Text size="paragraph_normal" textAlign="right">
                                                        {login.ipAddress}
                                                    </Text>
                                                </a>
                                            </Box>
                                        </Grid>
                                        {i < loginInfos.length - 1 && <Divider flexBasis="100%" borderColor="gray.700" mb="4" />}
                                    </Flex>
                                ))}
                            </>
                        )
                    )}
                </Flex>
            </Flex>
            {isAuthorized && loginInfoPagination && loginInfoPagination!.totalPages > 1 && (
                <Flex align="center" direction="row" justify="end" mt="4">
                    <Text size="smallText_semibold" color="gray.300" mr="3">
                        {t("displayed")} {getRecordNo(loginInfoPagination)} {t("logins")}
                    </Text>
                    <Button
                        variant="primary"
                        isLoading={loadingNext}
                        isDisabled={!(!loadingNext && !!loginInfoPagination && loginInfoPagination.currentPage < loginInfoPagination.totalPages)}
                        onClick={handleGetNext}
                        mr="3"
                    >
                        {t("isplay.more")}
                    </Button>
                </Flex>
            )}
        </Flex>
    );
});
