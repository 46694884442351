import * as React from "react";
// import { Intro, Rates, Security, Action, Steps, Questions, Call } from "features/landing";
import { history } from "../..";
import UnauthenticatedNavigation from "app/components/unauthenticatedNavigation";
import { useStore } from "app/stores/store";
import Footer from "app/layout/Footer";

import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Heading, Link, Text, useBreakpointValue } from "@chakra-ui/react";

export default function TermsCond() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();

    const toc = [
        { id: 1, value: "terms&conditions.p4", details: ["terms&conditions.p31", "terms&conditions.p32", "terms&conditions.p33"] },
        { id: 2, value: "terms&conditions.p5", details: ["terms&conditions.p35"] },
        { id: 3, value: "terms&conditions.p6", details: ["terms&conditions.p37", "terms&conditions.p38"] },
        { id: 4, value: "terms&conditions.p7", details: ["terms&conditions.p40", "terms&conditions.p41"] },
        { id: 5, value: "terms&conditions.p8", details: ["terms&conditions.p43", "terms&conditions.p44", "terms&conditions.p45"] },
        { id: 6, value: "terms&conditions.p9", details: ["terms&conditions.p47"] },
        {
            id: 7,
            value: "terms&conditions.p10",
            details: [
                "terms&conditions.p49",
                "terms&conditions.p50",
                "terms&conditions.p51",
                "terms&conditions.p52",
                "terms&conditions.p53",
                "terms&conditions.p54",
                "terms&conditions.p55",
                "terms&conditions.p56",
                "terms&conditions.p57",
                "terms&conditions.p58",
                "terms&conditions.p59",
                "terms&conditions.p60",
                "terms&conditions.p61",
                "terms&conditions.p62",
                "terms&conditions.p63",
                "terms&conditions.p64",
                "terms&conditions.p65",
                "terms&conditions.p66",
                "terms&conditions.p67",
                "terms&conditions.p68",
                "terms&conditions.p69",
                "terms&conditions.p70",
                "terms&conditions.p71",
                "terms&conditions.p72",
                "terms&conditions.p73",
            ],
        },
        {
            id: 8,
            value: "terms&conditions.p11",
            details: [
                "terms&conditions.p75",
                "terms&conditions.p76",
                "terms&conditions.p77",
                "terms&conditions.p78",
                "terms&conditions.p79",
                "terms&conditions.p80",
                "terms&conditions.p81",
                "terms&conditions.p82",
                "terms&conditions.p82",
                "terms&conditions.p83",
                "terms&conditions.p84",
                "terms&conditions.p85",
                "terms&conditions.p86",
                "terms&conditions.p87",
                "terms&conditions.p88",
                "terms&conditions.p89",
                "terms&conditions.p90",

            ],
        },
        { id: 9, value: "terms&conditions.p12", details: ["terms&conditions.p92", "terms&conditions.p93"] },
        {
            id: 10,
            value: "terms&conditions.p13",
            details: ["terms&conditions.p95", "terms&conditions.p96", "terms&conditions.p97", "terms&conditions.p98", "terms&conditions.p99"],
        },
        { id: 11, value: "terms&conditions.p14", details: ["terms&conditions.p101"] },
        { id: 12, value: "terms&conditions.p15", details: ["terms&conditions.p103", "terms&conditions.p104"] },
        { id: 13, value: "terms&conditions.p16", details: ["terms&conditions.p106"] },
        { id: 14, value: "terms&conditions.p17", details: ["terms&conditions.p108"] },
        { id: 15, value: "terms&conditions.p18", details: ["terms&conditions.p110", "terms&conditions.p111"] },
        { id: 16, value: "terms&conditions.p19", details: ["terms&conditions.p113", "terms&conditions.p114", "terms&conditions.p115"] },
        { id: 17, value: "terms&conditions.p20", details: ["terms&conditions.p117"] },
        {
            id: 18,
            value: "terms&conditions.p21",
            details: [
                "terms&conditions.p119",
                "terms&conditions.p120",
                "terms&conditions.p121",
                "terms&conditions.p122",
                "terms&conditions.p123",
                "terms&conditions.p124",
                "terms&conditions.p125",
                "terms&conditions.p126",
            ],
        },
        { id: 19, value: "terms&conditions.p22", details: ["terms&conditions.p128"] },
        { id: 20, value: "terms&conditions.p23", details: ["terms&conditions.p130", "terms&conditions.p131"] },
        { id: 21, value: "terms&conditions.p24", details: ["terms&conditions.p133"] },
        { id: 22, value: "terms&conditions.p25", details: ["terms&conditions.p135"] },
        { id: 23, value: "terms&conditions.p26", details: ["terms&conditions.p137"] },
        { id: 24, value: "terms&conditions.p27", details: ["terms&conditions.p139"] },
        { id: 25, value: "terms&conditions.p28", details: ["terms&conditions.p141"] },
        { id: 26, value: "terms&conditions.p29", details: ["terms&conditions.p143", "terms&conditions.p144"] },
    ];

    return (
        <Grid color="white" mt={"3em"} height="90%" px="1">
            <Flex
                direction="column"
                align="start"
                textAlign="left"
                maxW="container.xl"
                mx="auto"
                px="6"
            >
                <Heading size={headerSize} mb="2">
                    {t("terms&conditions.p1")}
                </Heading>
                <Text size="overline" color="gray.500" mb="10">
                    {t("terms&conditions.p2")}
                </Text>
            

            <Flex
                direction="column"
                align="start"
                textAlign="left"
                mx="auto"
                px="6"
                py="10"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
            >
                <Heading size="h4_bold" textAlign="left" mb="5">
                    {t("terms&conditions.p3")}
                </Heading>

                {toc.map((item) => (
                    <Link href={`#${item.id}`} key={item.id} size="paragraph_normal" textAlign="left">
                        {t(item.value)}
                    </Link>
                ))}

                {toc.map((item) => (
                    <>
                        <Text id={`${item.id}`} size="paragraph_semibold" textAlign="left" maxW="90ch" mb="3" mt="10">
                            {t(item.value)}
                        </Text>
                        {item.details?.map((detail) => (
                            <Text size="paragraph_normal" textAlign="left" align="left" mb="3">
                                {t(detail)}
                            </Text>
                        ))}
                    </>
                ))}
            </Flex>
            </Flex>
        </Grid>
    );
}
