import { Currency, geDisplayValue, isFiat } from "./rate";

export interface AccountOperation {
    id: string;
    amount: number;
    currency: Currency;
    isDebit: boolean;
    date: Date;
    category: number;
    transactionType: number;
}

export interface Transaction {
    id: string;
    fundingRequestId: string;
    accountOperations: AccountOperation[];
    type: number;
    status: TransactionStatus;
    ownerName: string;
    createdBy: string;
    date: Date;
    rollbacked: boolean;
    details: string;
    additionalInfo: string;
}

export class LedgerFilterParams {
    iban?: string;
    currency?: string[];
    isDebit?: boolean;
    type?: string[];
    status?: string[];
    ownerName?: string;
    startDate?: Date;
    endDate?: Date;
    minValue?: number;
}

export const TransactionType = {
    Deposit: 0,
    Withdrawal: 1,
    Exchange: 2,
};

export enum TransactionStatus {
    Pending = 0,
    Confirmed = 1,
    Canceled = 2,
}

// export function getTransactionStatusLabel(transactionStatus: TransactionStatus) {
//     switch (fundingRequestStatus) {
//         case FundingRequestStatus.Pending:
//             return "Pending";
//         case FundingRequestStatus.Approved:
//             return "Approved";
//         case FundingRequestStatus.Canceled:
//             return "Canceled";
//         default:
//             return "";
//     }
// }

export function getTransactionTypeLabel(transactionType: number) {
    switch (transactionType) {
        case TransactionType.Deposit:
            return "Deposit";
        case TransactionType.Withdrawal:
            return "Widthdraw";
        case TransactionType.Exchange:
            return "Trade";
        default:
            return "";
    }
}

export function getTransactionTypeOptions() {
    return [
        {
            text: getTransactionTypeLabel(TransactionType.Deposit),
            value: TransactionType.Deposit.toString(),
        },
        {
            text: getTransactionTypeLabel(TransactionType.Withdrawal),
            value: TransactionType.Withdrawal.toString(),
        },
        {
            text: getTransactionTypeLabel(TransactionType.Exchange),
            value: TransactionType.Exchange.toString(),
        },
    ];
}

export function getTransactionTypeIcon(transactionType: number) {
    switch (transactionType) {
        case TransactionType.Deposit:
            return "plus circle";
        case TransactionType.Withdrawal:
            return "minus circle";
        case TransactionType.Exchange:
            return "exchange";
        default:
            return "";
    }
}

export function getTransactionStatusLabel(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
        case TransactionStatus.Pending:
            return "Pending";
        case TransactionStatus.Confirmed:
            return "Confirmed";
        case TransactionStatus.Canceled:
            return "Canceled";
        default:
            return "";
    }
}

export function getTransactionStatusOptions() {
    return [
        {
            text: getTransactionStatusLabel(TransactionStatus.Pending),
            value: TransactionStatus.Pending.toString(),
        },
        {
            text: getTransactionStatusLabel(TransactionStatus.Confirmed),
            value: TransactionStatus.Confirmed.toString(),
        },
        {
            text: getTransactionStatusLabel(TransactionStatus.Canceled),
            value: TransactionStatus.Canceled.toString(),
        },
    ];
}

export function getTransactionStatusColor(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
        case TransactionStatus.Pending:
            return "yellow.800";
        case TransactionStatus.Confirmed:
            return "green.800";
        case TransactionStatus.Canceled:
            return "red.800";
        default:
            return "";
    }
}

export const AccountOperationCategory = {
    Normal: 0,
    Fee: 1,
    Referral: 2,
};

export function getAccountOperationCategoryLabel(accountOperationCategory: number) {
    switch (accountOperationCategory) {
        case AccountOperationCategory.Normal:
            return "Normal";
        case AccountOperationCategory.Fee:
            return "Fee";
        case AccountOperationCategory.Referral:
            return "Referral";
        default:
            return "";
    }
}

export function getTransactionRate(transaction: Transaction) {
    if (transaction.type != TransactionType.Exchange) return "";
    var fromOperation = transaction.accountOperations.find((op) => op.isDebit && op.category == AccountOperationCategory.Normal);
    var toOperation = transaction.accountOperations.find((op) => !op.isDebit && op.category == AccountOperationCategory.Normal);
    return geDisplayValue(
        {
            fromCurrency: fromOperation!.currency,
            toCurrency: toOperation!.currency,
            value: toOperation!.amount / fromOperation!.amount,
        },
        true
    );
}

function getDisplayAmount(amount: number, currency: Currency) {
    return amount.toFixed(isFiat(currency) ? 2 : 8);
}


export function getTransactionAmount(transaction: Transaction, positive: boolean) {
    var accountOperation = transaction.accountOperations.find((op) => op.isDebit != positive && op.category == AccountOperationCategory.Normal);
    var transactionType = positive ? TransactionType.Withdrawal : TransactionType.Deposit;
    var sign = positive ? "+" : "-";
    return transaction.type != transactionType && accountOperation ? sign + getDisplayAmount(accountOperation.amount, accountOperation.currency) + " " + accountOperation.currency : " ";
}



export function getTransactionCurrency(transaction: Transaction, positive: boolean) {
    var accountOperation = transaction.accountOperations.find((op) => op.isDebit != positive && op.category == AccountOperationCategory.Normal);
    return accountOperation && accountOperation.currency;
}

export function getTransactionAmountBeforeFees(transaction: Transaction) {
    var accountOperation = transaction.accountOperations.find((op) => op.isDebit && op.category == AccountOperationCategory.Normal);
    return accountOperation ? getDisplayAmount(accountOperation.amount, accountOperation.currency) + " " + accountOperation.currency : "";
}

export function getTransactionAmountAfterFees(transaction: Transaction) {
    var accountOperation = transaction.accountOperations.find((op) => !op.isDebit && op.category == AccountOperationCategory.Normal);
    return accountOperation ? getDisplayAmount(accountOperation.amount, accountOperation.currency) + " " + accountOperation.currency : "";
}

export function getTransactionFee(transaction: Transaction, ignoreExchangeFee: boolean = true) {
    if (ignoreExchangeFee && transaction.type == TransactionType.Exchange) return 0;

    var accountOperation = transaction.accountOperations.find((op) => op.category == AccountOperationCategory.Fee);
    return accountOperation ? getDisplayAmount(accountOperation.amount, accountOperation.currency) + " " + accountOperation.currency : " ";
}
