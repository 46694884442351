import * as React from "react";
import { Menu, BoxProps, MenuButton, MenuList, MenuItem, Tooltip } from "@chakra-ui/react";
import { ArrowDown, Account as AccountIcon, Settings, Logout } from "assets/icons/navigation";
import { history } from "../../..";
import { useStore } from "app/stores/store";
import { useTranslation } from "react-i18next";

export default function ProfileNav(props: BoxProps) {
    const { t } = useTranslation();
    const {
        userStore: { user, logout },
    } = useStore();
    return (
        <Menu {...props}>
            <Tooltip label={user?.username}>
                <MenuButton ml="auto" my="3">
                    <AccountIcon w="20px" h="20px" />
                    <ArrowDown ml="2" w="16px" h="16px" />
                </MenuButton>
            </Tooltip>
            <MenuList>
                {/* <MenuItem icon={<Settings w="16px" h="16px" mr="0.25rem" />} onClick={() => history.push(`/profiles/${user?.username}`)}>
          Settings - old
        </MenuItem> */}
                <MenuItem icon={<Settings w="16px" h="16px" mr="0.25rem" />} onClick={() => history.push(`/profiles/settings`)}>
                    {t("settings")}
                </MenuItem>
                <MenuItem icon={<Logout w="16px" h="16px" mr="0.25rem" />} onClick={logout}>
                    {t("log.out")}
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
