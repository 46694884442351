import { HubConnection } from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CryptoAccount } from "../models/cryptoAccount";
import { FIATAccount, FIATAccountFormValues } from "../models/fiatAccount";
import { IdentificationStatus, LoginInfo, LoginInfoFilterParams, Profile } from "../models/profile";
import { Document } from "../../app/models/document";
import { store } from "./store";
import { DocumentType } from "../../app/models/document";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import { CryptoCurrencies, Currencies, Currency, FiatCurrencies, isFiat } from "app/models/rate";
import { Asset } from "app/models/asset";
import { Pagination, PagingParams } from "app/models/pagination";
import { useTranslation } from "react-i18next";

export default class ProfileStore {
    profile: Profile | null = null;
    onboardingVariant: "start" | "end" = "start";
    loadingProfile = false;
    uploading = false;
    loading = false;
    hubConnection: HubConnection | null = null;
    lastUploadedDocumentId = "";
    accounts: (FIATAccount | CryptoAccount)[] | undefined; //only validated accounts
    isProfileValidated: boolean = false;
    loginInfos: LoginInfo[] = [];
    loginInfoPagination: Pagination | undefined = undefined;
    loginInfoPagingParams = new PagingParams(1, 3);
    loginInfoFilterParams = new LoginInfoFilterParams();

    constructor() {
        makeAutoObservable(this);
    }

    get isCurrentUser() {
        if (store.userStore.user && this.profile) {
            return store.userStore.user.username === this.profile.username;
        }
        return false;
    }

    get getIdentificationStatus() {
        return  (this.profile) ?  this.profile.identificationStatus : IdentificationStatus.Pending; 
    }

    getAssets = (isFiat?: boolean, hasBalance: boolean = false) => {
        var acceptedCurrencies = isFiat == undefined ? Currencies : isFiat ? FiatCurrencies : CryptoCurrencies;
        var assets = acceptedCurrencies
            .sort((a, b) => a.localeCompare(b))
            // .sort((a, b) => CurrencyLabel[a].localeCompare(CurrencyLabel[b]))
            .map((currency) => {
                var account = this.accounts?.find((x) => x.currency === currency);
                return account
                    ? new Asset({ currency: currency, balance: account.balance, accountId: account.id, iban: account.iban })
                    : new Asset({ currency: currency, balance: 0 });
            });
        return hasBalance ? assets.filter((x) => x.balance > 0) : assets;
    };

    get hasFunds() {
        return this.getAssets(undefined, true).length > 0;
    }

    getWhitelistAdddressList = (currency: Currency) => {
        if (!isFiat(currency)) {
            var acc = this.accounts?.find((x) => x.currency === currency) as CryptoAccount;
            if (!!acc.whitelistedAddresses) return acc.whitelistedAddresses.filter((x) => x.verified);
        }
    };

    getDocumentByType = (documentType: DocumentType) => {
        return this.profile?.documents?.find((p) => p.type == documentType);
    };

    loadProfile = async (username: string) => {
        this.loadingProfile = true;
        try {
            const profile = await agent.Profiles.get(username);
            runInAction(() => {
                profile.dob = new Date(profile.dob + "Z");
                profile.fiatAccounts?.forEach((acc) => (acc.date = new Date(acc.date + "Z")));
                this.profile = profile;
                this.isProfileValidated = profile.kycValidated;
                if (profile.kycValidated) {
                    this.accounts = [...profile.fiatAccounts?.filter((x) => x.validated)!, ...profile.cryptoAccounts!];
                }
                //console.log(profile);
                this.loadingProfile = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loadingProfile = false));
        }
    };

    uploadDocument = async (type: DocumentType, comment: string, file: Blob) => {
        this.uploading = true;
        try {
            const response = await agent.Documents.create(type.toString(), comment, file);
            const document = response.data;
            runInAction(() => {
                this.lastUploadedDocumentId = document.id;
                if (this.profile) {
                    this.profile.documents?.unshift(document);
                }
                this.uploading = false;
                showToast("doc.uploaded", MessageType.Success);
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.uploading = false));
        }
    };

    deleteDocument = async (document: Document) => {
        this.loading = true;
        try {
            await agent.Documents.delete(document.id);
            runInAction(() => {
                if (this.profile) {
                    this.profile.documents = this.profile.documents?.filter((p) => p.id !== document.id);
                    this.loading = false;
                }
                showToast("doc.deleted", MessageType.Success);
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    validateDocument = async (document: Document) => {
        this.loading = true;
        try {
            await agent.Documents.validate(document.id);
            runInAction(() => {
                if (this.profile) {
                    var doc = this.profile.documents?.find((p) => p.id == document.id);
                    if (doc) {
                        doc.validated = true;
                        showToast("doc.validated", MessageType.Success);
                    }
                    this.loading = false;
                }
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    createFIATAccount = async (fiatAccount: FIATAccountFormValues, file?: Blob) => {
        this.loading = true;
        try {
            if (file != null) {
                const response = await agent.Documents.create(DocumentType.Bank.toString(), "bank", file);
                const document = response.data;
                runInAction(() => {
                    fiatAccount.documentId = document.id;
                    if (this.profile) {
                        this.profile.documents?.unshift(document);
                    }
                });
            }
            const response = await agent.FiatAccounts.create(fiatAccount);
            runInAction(() => {
                if (this.profile) {
                    response.date = new Date(response.date);
                    this.profile.fiatAccounts?.unshift(response);
                }
            });
            showToast("account.created", MessageType.Success);
        } catch (error) {
            // //console.log(error);
            throw error;
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    validateFIATAccount = async (fiatAccountId: string) => {
        this.loading = true;
        try {
            await agent.FiatAccounts.validate(fiatAccountId);
            runInAction(() => {
                if (this.profile) {
                    var account = this.profile.fiatAccounts?.find((p) => p.id == fiatAccountId);
                    //console.log(account);
                    if (account) {
                        account.validated = true;
                        //console.log(account?.document.id);
                        var doc = this.profile.documents?.find((p) => p.id == account?.document.id);
                        if (doc) doc.validated = true;
                        showToast("acc.activated", MessageType.Success);
                    }
                    this.loading = false;
                }
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    deleteFIATAccount = async (fiatAccountId: string) => {
        this.loading = true;
        try {
            await agent.FiatAccounts.delete(fiatAccountId);
            runInAction(() => {
                if (this.profile) {
                    this.profile.fiatAccounts = this.profile.fiatAccounts?.filter((p) => p.id !== fiatAccountId);
                }
            });
            showToast("acc.deleted", MessageType.Success);
        } catch (error) {
            //console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    createCryptoAccount = async (currency: Currency) => {
        this.loading = true;
        try {
            const account = await agent.CryptoAccounts.create(currency);
            runInAction(() => {
                if (this.profile) {
                    account.date = new Date(account.date);
                    this.profile.cryptoAccounts?.unshift(account);
                }
                showToast("acc.activated", MessageType.Success);
            });
            // toast.success("New address generated");
        } catch (error) {
            console.log(error);
            showToast("acc.not.activated", MessageType.Error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    createWhitelistedAddress = async (currency: string, address: string, label: string) => {
        this.loading = true;
        try {
            var whitelistedAddress = {
                accountId: "",
                address: address,
                label: label,
            };
            if (this.profile) {
                var account = this.profile.cryptoAccounts?.find((x) => x.currency == currency);
                if (account) {
                    whitelistedAddress.accountId = account.id;
                } else {
                    account = await agent.CryptoAccounts.create(currency);
                    runInAction(() => {
                        if (account) {
                            account.date = new Date(account?.date);
                            this.profile?.cryptoAccounts?.unshift(account);
                            whitelistedAddress.accountId = account.id;
                        }
                    });
                }
            }
            const response = await agent.WhitelistedAddresses.create(whitelistedAddress);
            
            runInAction(() => {
                if (this.profile) {
                    var account = this.profile.cryptoAccounts?.find((x) => x.id == whitelistedAddress.accountId);
                    if (account) {
                        account.whitelistedAddresses?.unshift(response);
                        showToast("address.created", MessageType.Success);
                    }
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    deleteWhitelistedAddress = async (id: string, currency: string) => {
        this.loading = true;
        try {
            await agent.WhitelistedAddresses.delete(id);
            runInAction(() => {
                if (this.profile) {
                    const account = this.profile.cryptoAccounts?.find((x) => x.currency == currency);
                    if (account) {
                        account.whitelistedAddresses = account.whitelistedAddresses?.filter((a) => a.id !== id);
                    }
                }
            });
            showToast("address.deteled", MessageType.Success);
        } catch (error) {
            //console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    // readDocument = async (document: Document) => {
    //     this.loading = true;
    //     try {
    //         // agent.Profiles.readPhoto(photo.id).then((result) => {return result});
    //         var result = await agent.Profiles.readPhoto(document.id);
    //         runInAction(() => {
    //             if (this.profile && this.profile.photos) {
    //                 this.profile.photos.find(p => p.isMain)!.data = result;
    //                 this.loading = false;
    //             }
    //         })
    //     } catch (error) {
    //         runInAction(() => this.loading = false);
    //         //console.log(error);
    //     }
    // }

    updateProfile = async (profile: Partial<Profile>) => {
        this.loading = true;
        try {
            await agent.Profiles.updateProfile(profile);
            runInAction(() => {
                if (profile.displayName && profile.displayName !== store.userStore.user?.displayName) {
                    store.userStore.setDisplayName(profile.displayName);
                }
                this.profile = { ...this.profile, ...(profile as Profile) };
                //console.log(this.profile);
                this.loading = false;
                showToast("profile.updated", MessageType.Success);
            });
        } catch (error) {
            //console.log(error);
            store.commonStore.setNotification({
                message: "error.save",
                type: MessageType.Error,
            });
            runInAction(() => (this.loading = false));

            throw error;
        }
    };

    validateProfile = async (email: string) => {
        this.loading = true;
        try {
            await agent.Account.validate(email);
            runInAction(() => {
                this.profile!.kycValidated = true;
                this.loading = false;
            });
            showToast("profile.validated", MessageType.Success);
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    startIdentification = async () => {
        this.loading = true;
        try {
            showToast("new.window", MessageType.Info);
            const url = await agent.Profiles.startIdentification();
            return url;
            // toast.success("New address generated");
        } catch (error) {
            console.log(error);
            showToast("Account not activated", MessageType.Error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    setOnboardingVariant = (onboardingVariant: "start" | "end") => {
        this.onboardingVariant = onboardingVariant;
    };

    loadLoginInfos = async (isAdmin: boolean = false) => {
        //console.log("username: " + this.profile?.username);
        if (this.profile == null) return;
        this.loading = true;
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", this.loginInfoPagingParams.pageNumber.toString());
            params.append("pageSize", this.loginInfoPagingParams.pageSize.toString());
            // if (this.loginInfoFilterParams) {
            //     if (this.loginInfoFilterParams.userName) params.append("userName", this.loginInfoFilterParams.userName);
            // }
            //console.log("username: " + this.profile.username);
            params.append("userName", this.profile.username);
            var result = isAdmin ? await agent.Profiles.listLoginInfoAsAdmin(params) : await agent.Profiles.listLoginInfo(params);
            const logins = result.data;
            //console.log(logins.length);
            runInAction(() => {
                logins.forEach((login) => {
                    login.date = new Date(login.date + "Z");
                });
                this.loginInfos.push(...logins);
                // this.loginInfos = logins;
                this.setLoginInfoPagination(result.pagination);
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    setLoginInfoPagination = (pagination: Pagination) => {
        this.loginInfoPagination = pagination;
    };

    setLoginInfoPagingParams = (pagingParams: PagingParams) => {
        this.loginInfoPagingParams = pagingParams;
    };

    setLoginInfoFilterParams = (filterParams: LoginInfoFilterParams, reLoad: boolean = true) => {
        this.loginInfoFilterParams = filterParams;
        this.loginInfoPagingParams = new PagingParams(1, this.loginInfoPagingParams.pageSize);
        this.loginInfos = [];
        if (reLoad) this.loadLoginInfos();
    };

    //use loadProfile instead
    // loadAccounts = async (username: string) => {
    //     this.loading = true;
    //     try {
    //         const profile = await agent.Profiles.get(username);
    //         runInAction(() => {
    //             this.isProfileValidated = profile.kycValidated;
    //             if (profile.kycValidated) {
    //                 this.accounts = [...profile.fiatAccounts?.filter((x) => x.validated)!, ...profile.cryptoAccounts!];
    //             }
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };
}
