import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getCurrencyOptions, Rate } from "../../../app/models/rate";
import { Regx } from "../../../app/common/util/regx";

interface Props {
    setAddRateFeeMode: (mode: boolean) => void;
    selectedFee: Rate | undefined;
}

export default observer(function RateFeeForm({ setAddRateFeeMode, selectedFee }: Props) {
    const {
        adminStore: { createRateFee, updateRateFee },
    } = useStore();
    const isNew = selectedFee == null;
    const rateFee = selectedFee == null ? new Rate() : selectedFee;
    // const [fundingFee, setFundingFee] = useState<FundingFee>(selectedFee == null ? new FundingFee() : selectedFee);

    const validationSchema = Yup.object({
        fromCurrency: Yup.string().required("From Currency is required"),
        toCurrency: Yup.string().required("To Currency is required"),
        value: Yup.number()
            .test("is-decimal", "Invalid number", (number) => Regx.Decimal2.test(number + ""))
            .required("Value is required")
            .min(0, "Must be a positive number")
            .max(50, "Value too big"),
    });

    function handleFormSubmit(rateFee: Rate) {
        if (isNew) {
            createRateFee(rateFee).then(() => {
                setAddRateFeeMode(false);
            });
        } else {
            updateRateFee(rateFee).then(() => {
                setAddRateFeeMode(false);
            });
        }
    }

    return (
        <Formik
            initialValues={rateFee}
            onSubmit={(values) => {
                handleFormSubmit(values);
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <MySelectInput options={getCurrencyOptions()} label="From Currency" name="fromCurrency" isRequired isDisabled={!isNew} />
                    <MySelectInput options={getCurrencyOptions()} label="To Currency" name="toCurrency" isRequired isDisabled={!isNew} />
                    <MyTextInput label="Percentage Value" name="value" isRequired mb="6" />
                    <Button variant="primary" type="submit" isLoading={isSubmitting} isDisabled={!isValid || !dirty} isFullWidth>
                        Save
                    </Button>
                </Form>
            )}
        </Formik>
    );
});
