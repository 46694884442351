import { Button, Heading } from "@chakra-ui/react";
import Notification from "app/components/notification";
import ReadOnlyField from "app/components/readOnlyField";
import { MessageType } from "app/models/message";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "app/layout/LoadingComponent";
import { isFiat } from "app/models/rate";
import { useTranslation } from "react-i18next";

export default observer(function WithdrawSummary() {
    const { t } = useTranslation();
    const {
        adminStore: { loadFundingFees, fundingFees, getFundingFeeLabel, getMinFundingFee },
        profileStore: { loadProfile },
        userStore: { user },
        fundingRequestStore: { loading, setShowSummary, createFundingRequest, fundingRequestFormValues },
    } = useStore();

    useEffect(() => {
        // if (fundingFees.length == 0) 
        loadFundingFees();
    }, [loadFundingFees]);

    if (loading) return <LoadingComponent />;

    return (
        <>
            <Heading size="h4_semibold" mb="10">
                {t("widthdraw")} {fundingRequestFormValues?.amount} {fundingRequestFormValues?.currency} {t("deposit.in.acc")}
            </Heading>
            {isFiat(fundingRequestFormValues?.currency) ? (
                <ReadOnlyField value={fundingRequestFormValues?.externalId!} label={t("recipient.IBAN")} mb="4" />
            ) : (
                <ReadOnlyField value={fundingRequestFormValues?.externalId!} label={t("profile.Whitelist.tab")} mb="4" />
            )}

            <Notification
                messageStyle={{ fontWeight: "normal" }}
                message={t("withdraw.summary") + `${
                    fundingRequestFormValues?.currency && getFundingFeeLabel(fundingRequestFormValues?.currency, true, t("fee.msg"))
                }`}
                type={MessageType.Info}
                bg="gray.700"
                mb="10"
            />

            {fundingRequestFormValues?.currency &&
                fundingRequestFormValues!.amount &&
                getMinFundingFee(fundingRequestFormValues?.currency, true) >= fundingRequestFormValues!.amount && (
                    <Notification
                        messageStyle={{ fontWeight: "normal" }}
                        message={t("small.amount")}
                        type={MessageType.Error}
                        bg="gray.700"
                        mb="10"
                    />
                )}
            <Button
                variant="primary"
                w="100%"
                isDisabled={
                    fundingRequestFormValues?.currency && fundingRequestFormValues!.amount
                        ? getMinFundingFee(fundingRequestFormValues?.currency, true) >= fundingRequestFormValues!.amount
                        : true
                }
                onClick={() => {
                    if (fundingRequestFormValues != null) {
                        // createFundingRequest({ accountId: account?.id!, isDebit: true, amount: amount, externalId: selectedAddress?.value });
                        createFundingRequest(fundingRequestFormValues!).then(() => loadProfile(user?.username!));
                    }
                    setShowSummary(false);
                }}
            >
                {t("submit.request.bttn")}
            </Button>
        </>
    );
});
