export interface Rate {
    fromCurrency: Currency;
    toCurrency: Currency;
    value: number;
}
export class Rate implements Rate {}

// export const Currency = {
//     RON: "RON",
//     EUR: "EUR",
//     BTC: "BTC",
//     ETH: "ETH",
//     LTC: "LTC",
// };

export enum Currency {
    // RON = "RON",
    EUR = "EUR",
    BTC = "BTC",
    // ETH = "ETH",
    // LTC = "LTC",
    // DOGE = "DOGE",
}

export enum CurrencyLabel {
    // RON = "Leu",
    EUR = "Euro",
    BTC = "Bitcoin",
    // ETH = "Ethereum",
    // LTC = "Litecoin",
    // DOGE = "Dogecoin",
}

export enum CurrencyColor {
    // RON = "purple",
    EUR = "pink",
    BTC = "orange",
    // ETH = "teal",
    // LTC = "cyan",
    // DOGE = "teal",
}

export const FiatCurrencies = [Currency.EUR]//, Currency.RON];

export const CryptoCurrencies = [Currency.BTC]//, Currency.LTC, Currency.DOGE];

export const Currencies = [...FiatCurrencies, ...CryptoCurrencies];

export function isFiat(currency: Currency | undefined) {
    if (currency == undefined) return false;
    return FiatCurrencies.includes(currency);
}
export function isCurrency(currency: Currency | undefined) {
    return currency && Currencies.includes(currency);
    //return currency && (<any>Object).values(Currencies).includes(currency);
}

export function getCurrencyLabel(currency: Currency) {
    return CurrencyLabel[currency];
}

export function getCurrencyOptions() {
    return Currencies.map((currency) => ({ text: CurrencyLabel[currency], value: currency }));
}

export function getFiatCurrencyOptions() {
    return FiatCurrencies.map((currency) => ({ text: CurrencyLabel[currency], value: currency }));
}

export function getCryptoCurrencyOptions() {
    return CryptoCurrencies.map((currency) => ({ text: CurrencyLabel[currency], value: currency }));
}

export function getCurrencyItems() {
    return Currencies.sort((a, b) => a.localeCompare(b)).map((currency) => ({
        text: CurrencyLabel[currency].toString(),
        value: currency.toString(),
    }));
}

export function getCurrencyConfirmations(currency: Currency) {
    switch (currency) {
        case Currency.BTC:
            return 3;
        // case Currency.LTC:
        //     return 5;
        // case Currency.DOGE:
        //     return 10;
        default:
            return 3;
    }
}

export function geDisplayValue(rate: Rate, extended: boolean = false) {
    if (rate == undefined) return "0";
    return  isFiat(rate.toCurrency)
        ? (extended ? "1 " + rate.fromCurrency + " = " : "") + rate.value.toFixed(2) + " " + rate.toCurrency
        : (extended ? "1 " + rate.toCurrency + " = " : "") + (1 / rate.value).toFixed(2) + " " + rate.fromCurrency;
}

export function geDisplayValueInCrypto(rate: Rate, extended: boolean = false) {
    if (rate == undefined) return "0";
    return  !isFiat(rate.toCurrency)
        ? (extended ? "1 " + rate.fromCurrency + " = " : "") + rate.value.toFixed(8) + " " + rate.toCurrency
        : (extended ? "1 " + rate.toCurrency + " = " : "") + (1 / rate.value).toFixed(8) + " " + rate.fromCurrency;
}

