import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import MySelectInput from "../../app/common/form/MySelectInput";
import { CryptoAccount } from "../../app/models/cryptoAccount";
import { Button } from "@chakra-ui/react";
import { getCryptoCurrencyOptions } from "app/models/rate";
import { useTranslation } from "react-i18next";

interface Props {
    setAddAddressMode: (editMode: boolean) => void;
}

export default observer(function WhitelistedAddressForm({setAddAddressMode}: Props) {
    const { t } = useTranslation();
    const {profileStore: {profile, createWhitelistedAddress, }} = useStore();
    // const [account, setAccount] = useState<FIATAccountFormValues>(new FIATAccountFormValues());

    const validationSchema = Yup.object({
        currency: Yup.string().required (t("currency.required")),
        address: Yup.string().required(t("address.required")),
        
    })

    function getCurrencyOptions(cryptoAccounts: CryptoAccount[])  {
        return cryptoAccounts.map(
            (cryptoAccount) =>  ({text: cryptoAccount.currency, value: cryptoAccount.id}))
    }

    return (
        <Formik
            initialValues={{currency: "", address: "", label: ""}}
            onSubmit={values => {
                createWhitelistedAddress(values.currency, values.address, values.label).then(() => {setAddAddressMode(false);})
            }}
            validationSchema={validationSchema}
        >
            {({isSubmitting, isValid, dirty}) => (
                <Form>
                    <MySelectInput options={getCryptoCurrencyOptions()} label={t("currency")} name='currency' isRequired/>
                    <MyTextInput label={t("profile.address")} name='address' isRequired/>
                    <MyTextInput label={t("label")} name='label' />
                    <Button 
                        type='submit'
                        variant="primary"
                        isLoading={isSubmitting}
                        isDisabled={!isValid || !dirty}
                        isFullWidth
                    >{t("save,bttn")}</Button>
                </Form>
            )}
        </Formik>
    )
})
