import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { DocumentType } from "../../app/models/document";
import { useStore } from "app/stores/store";
import { useEffect, useState } from "react";
import DocumentUploadForm from "features/documents/DocumentUploadForm";
import ProfileIdentificationCard from "features/profiles/ProfileIdentificationCard";
import React from "react";
import { useTranslation } from "react-i18next";

export default observer(function Four() {
    const { t } = useTranslation();
    const hideLogo = useBreakpointValue({ base: true, sm: false });
    const {
        profileStore,
        userStore: { user },
    } = useStore();
    const [firstTime, setFirstTime] = React.useState(true);

    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);

    function handleStartIdentification() {
        setFirstTime(false);
        profileStore.startIdentification().then((url) => {
            window.open(url!);
            history.push("/onboarding/six")
            // window.location.href = url!;
        });
    }

    return (
        <>
            <OnboardingSteps steps={5} currentStep={4} />
            <AuthenticationTemplate
                headingText={t("Verify.identity")}
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            {t("verify.identity.mess1")}
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            {t("verify.identity.mess2")}
                        </Text>
                    </>
                }
            >
                <Text size="paragraph_normal" color="white" mb="6">
                    {t("verify.identity.mess3")}
                </Text>
                <Text size="paragraph_normal" color="white" mb="6">
                    {t("verify.identity.mess4")}
                </Text>

                    <Button variant="primary" onClick={handleStartIdentification} isLoading={profileStore.loading}>
                        {t("start.identification")}
                    </Button>

                {/* <DocumentUploadForm documentType={DocumentType.ID} submitButtonText="Continue" saveCallback={() => history.push("/onboarding/five") }/> */}
            </AuthenticationTemplate>
        </>
    );
});
