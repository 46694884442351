import { Flex, FlexProps, Text, Button, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Box } from "@chakra-ui/react";
import { IdCard, Selfie, Residence } from "assets/icons/settings";
import { Loader } from "assets/icons/home";
import { EmptyCheck } from "assets/icons/onboarding";
import { DocumentType } from "app/models/document";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import { createDownloadLink, Document } from "app/models/document";
import { useTranslation } from "react-i18next";
import { getIdentificationStatusLabel, IdentificationStatus } from "app/models/profile";
import { SolidX, Unchecked } from "assets/icons/unauthenticated";
import React from "react";


type Variants = "initial" | "pending" | "verified";

interface Props extends FlexProps {
    uploadCallback: () => void;
    documentType: DocumentType;
}

function getIcon(documentType: DocumentType) {
    switch (documentType) {
        case DocumentType.ID:
            return <IdCard w="20px" h="20px" />;
        case DocumentType.Selfie:
            return <Selfie w="20px" h="20px" />;
        case DocumentType.POR:
            return <Residence w="20px" h="20px" />;
    }
}

function getText(documentType: DocumentType) {
    
    switch (documentType) {
        case DocumentType.ID:
            return "ID";
        case DocumentType.Selfie:
            return "Selfie";
        case DocumentType.POR:
            return "Residence";
    }
}

export default observer(function ProfileIdentificationCard({ ...rest }: FlexProps) {
    const {
        modalStore,
        profileStore: { isCurrentUser, startIdentification, profile, getIdentificationStatus, loading },
        userStore: { isAuthorized },
    } = useStore();
    const documentType = DocumentType.ID;
    const { t } = useTranslation();

    function handleStartIdentification() {
        startIdentification().then((url) => {
            window.open(url!);
            // window.location.href = url!;
        });
    }

    return (
        <>
            <Flex
                py={{ base: "4", sm: "6" }}
                px={{ base: "4", sm: "6" }}
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                justify="space-between"
                align="center"
                {...rest}
            >
                <Flex
                    basis="40%"
                    justify="space-between"
                    align={{ base: "flex-start", sm: "center" }}
                    direction={{ base: "column", sm: "row" }}
                    sx={{ gap: "12px" }}
                >
                    <Flex color="white" align="center" mb={{ base: "4", sm: "0" }}>
                        <Flex
                            borderRadius="50%"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="gray.900"
                            minW="40px"
                            minH="40px"
                            align="center"
                            justify="center"
                            bg="gray.700"
                        >
                            {getIcon(documentType)}
                        </Flex>
                        <Text size="paragraph_semibold" ml="4">
                            {getText(documentType)}
                        </Text>
                    </Flex>
                    {profile?.identificationId && (
                        <Flex align="center">
                            {getIdentificationStatus == IdentificationStatus.Approved ? (
                                <EmptyCheck w="20px" h="20px" color="green.500" />
                            ) : getIdentificationStatus == IdentificationStatus.Rejected ? (
                                <Unchecked w="20px" h="20px" color="yellow.800" />
                            ) : (
                                <Loader w="20px" h="20px" color="yellow.800" />
                            )}
                            <Text ml="3" size="caption_bold">
                                {getIdentificationStatusLabel(getIdentificationStatus)}
                            </Text>
                        </Flex>
                    )}
                </Flex>

                <Flex height="min-content">
                    {isCurrentUser && getIdentificationStatus !== IdentificationStatus.Approved && (
                        <Button variant="primary" onClick={handleStartIdentification} isLoading={loading}>
                            {t("start.identification")}
                        </Button>
                    )}
                </Flex>
            </Flex>
        </>
    );
});
