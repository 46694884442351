import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
    Heading,
    useBreakpointValue,
    Spinner,
} from "@chakra-ui/react";
import Grid from "app/components/grid";
import { useStore } from "../../../app/stores/store";
import BalanceCard from "./BalanceCard";
import DateFilterPopover from "app/components/filterPopover/DateFilterPopover";
import { BalanceFilterParams } from "app/models/balanceDto";

export default observer(function TotalBalances() {
    const {
        reportsStore: {
            loading,
            feeBalances,
            loadFeeBalances,
            feeBalanceFilterParams,
            setFeeBalanceFilterParams,
            customerBalances,
            loadCustomerBalances,
            clearBalances,
        },
        userStore: { isAuthorized },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [loadingNext, setLoadingNext] = useState(false);

    useEffect(() => {
        //console.log("balance");
        if (isAuthorized) {
            if (feeBalances.length < 1) {
                loadFeeBalances();
            }
            if (customerBalances.length < 1) {
                loadCustomerBalances();
            }
        }
        return () => {
            setFeeBalanceFilterParams(new BalanceFilterParams(), false);
            clearBalances();
        };
    }, [isAuthorized, loadFeeBalances, setFeeBalanceFilterParams, loadCustomerBalances]);

    function handleApplyDateInterval(newStartDate?: Date, newEndDate?: Date) {
        var newFP = feeBalanceFilterParams;
        newFP.startDate = newStartDate;
        newFP.endDate = newEndDate;
        //console.log(JSON.stringify(newFP));
        setFeeBalanceFilterParams(newFP);
    }

    // if (loading) return <LoadingComponent />;

    return (
        <Grid mt={{ base: "6", lg: "10" }} pos="relative">
            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize} key="h1">
                Customers Balances
            </Heading>

            {/* <Flex gridColumn={{ base: "1/-1" }} mb="3">
                <Flex align="center" justify="flex-start">
                    <InputGroup mr="3">
                        <Input variant="filled" placeholder="Search..." value={userName} onChange={(e) => setUserName(e.target.value)} />
                        <InputRightElement my="auto" bottom="0" cursor="pointer" onClick={() => setUserName("")}>
                            {userName ? <Close w="16px" h="16px" color="white" /> : null}
                        </InputRightElement>
                    </InputGroup>
                    <Switch colorScheme="yellow" isChecked={kycValidated} onChange={() => setKycValidated(!kycValidated)} />
                    <Text as="span" ml="3" color="white" size="paragraph_semibold" whiteSpace="nowrap" mr="3">
                        KYC Validated
                    </Text>
                </Flex>
                <Spacer />
                <Button
                    variant="primary"
                    onClick={() => {
                        applyFilterHandler();
                    }}
                >
                    Filter
                </Button>
            </Flex> */}
            <Grid
                spacerStyles={{ display: "none" }}
                gridColumn="1/-1"
                mt={{ base: "4", sm: "6" }}
                gridProps={{ rowGap: { base: "0.5rem", sm: "1.5rem" } }}
                mb={{ base: "4", lg: "20" }}
            >
                {customerBalances &&
                    customerBalances.length > 0 &&
                    customerBalances.map((customerBalance) => (
                        <>
                            <BalanceCard
                                key={customerBalance.currency}
                                balance={customerBalance}
                                gridColumn={{
                                    base: "span 2",
                                    md: "span 6",
                                    lg: "span 4",
                                    xl: "span 3",
                                }}
                            />
                        </>
                    ))}
            </Grid>

            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize} key="h2">
                Fees Balances
            </Heading>

            <DateFilterPopover
                key="date"
                buttonText="Date"
                initialStartDate={feeBalanceFilterParams.startDate}
                initialEndDate={feeBalanceFilterParams.endDate}
                applyHandler={handleApplyDateInterval}
            >
                <></>
            </DateFilterPopover>
            <Grid
                spacerStyles={{ display: "none" }}
                gridColumn="1/-1"
                mt={{ base: "4", sm: "6" }}
                gridProps={{ rowGap: { base: "0.5rem", sm: "1.5rem" } }}
                mb={{ base: "4", lg: "20" }}
            >
                {loading ? (
                    <Spinner key="spinner" alignContent={"center"} color="yellow.500" size="xl" />
                ) : (
                    feeBalances &&
                    feeBalances.length > 0 &&
                    feeBalances.map((feeBalance) => (
                        <>
                            <BalanceCard
                                key={"f" + feeBalance.currency}
                                balance={feeBalance}
                                gridColumn={{
                                    base: "span 2",
                                    md: "span 6",
                                    lg: "span 4",
                                    xl: "span 3",
                                }}
                            />
                        </>
                    ))
                )}
            </Grid>
        </Grid>
    );
});
