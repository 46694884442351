import Grid from "app/components/grid";
import { Flex, Heading, Text, BoxProps } from "@chakra-ui/react";
import Document from "./Document";
import { history } from "../..";
import { useStore } from "app/stores/store";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  handler: () => void;
}

export default function WelcomingDocuments({ handler, ...rest }: Props) {
  const { t } = useTranslation();
  const {
    profileStore: {  profile },
} = useStore();
  return (
    <Grid my={{ base: "6", lg: "10" }} {...rest}>
      <Flex color="white" gridColumn="1 / -1" direction="column" mb="10">
        <Heading mb="2" size="h2_bold">
          {t("welcome")} {profile?.displayName}
        </Heading>
        <Text size="paragraph_normal">
          {t("first.steps.mess")}
        </Text>
      </Flex>
      <Document
        gridColumn={{ base: "1/-1", md: "1 / span 4" }}
        mb={{ base: "4", md: "0" }}
        step={1}
        variant="progress"
        buttonText="Complete profile"
        handleClick={handler}
      />
      <Document
        gridColumn={{ base: "1/-1", md: "5 / span 4" }}
        mb={{ base: "4", md: "0" }}
        step={2}
        variant="done"
        buttonText="Upload"
        handleClick={handler}
      />
      <Document
        gridColumn={{ base: "1/-1", md: "9 / span 4" }}
        step={3}
        variant="none"
        buttonText="Add a Bank Account"
        handleClick={ () => history.push("/profiles/settings/3")}
      />
    </Grid>
  );
}
