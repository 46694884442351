import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Text, useBreakpointValue } from "@chakra-ui/react";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { DocumentType } from "../../app/models/document";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import DocumentUploadForm from "features/documents/DocumentUploadForm";
import { useTranslation } from "react-i18next";

export default observer(function Six() {
    const hideLogo = useBreakpointValue({ base: true, sm: false });
    const { profileStore, userStore: { user } } = useStore();
    const { t } = useTranslation();


    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);
    
    return (
        <>
            <OnboardingSteps steps={5} currentStep={4} />
            <AuthenticationTemplate
                headingText={t("Verify.identity2")}
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                         <Text size="paragraph_normal" color="white" mb="6">
                            {t("verify.identity.mess2")}
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            {t("documents.criteria,residence.p1")}
                        </Text>
                    </>
                }
            >
                <DocumentUploadForm documentType={DocumentType.POR} submitButtonText={t("continue.bttn")} saveCallback={() => history.push("/onboarding/seven") }/>
                
            </AuthenticationTemplate>
        </>
    );
});

