import * as React from "react";
import { FlexProps, Flex, Heading, Button, Image, Text, useToast, useClipboard } from "@chakra-ui/react";
import { Toast } from "app/components/toast";
import { Copy, Loader } from "assets/icons/home";
import { FIATAccount } from "app/models/fiatAccount";
import { CurrencyLabel } from "app/models/rate";
import { EmptyCheck } from "assets/icons/onboarding";
import { useStore } from "app/stores/store";
import { useTranslation } from "react-i18next";

interface AccountProps extends FlexProps {
    account: FIATAccount;
    viewCallback: () => void;
}

export function ProfileFiatAccountCard({ account, viewCallback, ...rest }: AccountProps) {
    const {
        profileStore: {  deleteFIATAccount, isCurrentUser},
    } = useStore();
    const toast = useToast();
    const { hasCopied, onCopy } = useClipboard("crypto address");
    const { t } = useTranslation();
    React.useEffect(() => {
        if (hasCopied) {
            toast({
                position: "bottom",
                duration: 1000,
                render: () => <Toast icon={<Copy w="20px" h="20px" color="yellow.800" />} message={`Address copied to clipboard`} />,
            });
        }
    }, [hasCopied, toast]);

    return (
        <Flex
            p={{ base: "4", sm: "6" }}
            borderRadius="0.75rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            bg="gray.800"
            align={{ base: "flex-start", md: "center" }}
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            {...rest}
        >
            <Flex
                basis="40%"
                justify="space-between"
                align={{ base: "flex-start", sm: "center" }}
                direction={{ base: "column", sm: "row" }}
                sx={{ gap: "12px" }}
                mb={{base: "4", md: "0"}}
            >
                <Flex align="center" mb={{ base: "0", sm: "0" }}>
                    <Image src={"/coins/" + account.currency.toLowerCase() + ".svg"} alt="" minW="40px" minH="40px" h="0" w="0" mr="4" />
                    <Flex direction="column">
                        <Text size="paragraph_bold" color="white">
                            {CurrencyLabel[account.currency]}
                        </Text>
                        <Text size="smallText_normal" color="gray.500">
                             {account.iban}
                        </Text>
                    </Flex>
                </Flex>
                <Flex align="center">
                    {account.validated ? (
                        <>
                            <EmptyCheck w="20px" h="20px" color="green.500" />
                            <Text ml="3" size="caption_bold">
                                {t("validated")}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Loader w="20px" h="20px" color="yellow.800" />
                            <Text ml="3" size="caption_bold">
                                {t("verifying")}
                            </Text>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex align="center" justify="center" direction="row" w={{base: "100%", md: "min-content"}}>
                {!account.validated && isCurrentUser && (
                    <Button variant="secondary" onClick={() => deleteFIATAccount(account.id)} mr="3" w={{base: "100%", md: "min-content"}}>
                        {t("remove")}
                    </Button>
                )}
                <Button variant="primary" onClick={viewCallback} w={{base: "100%", md: "min-content"}}>
                    {t("view")}
                </Button>
            </Flex>
        </Flex>
    );
}
