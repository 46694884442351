import { Flex, FlexProps, Heading, Text, Button, Image, Spinner } from "@chakra-ui/react";
import { DepositWithdraw, Trade as TradeIcon } from "assets/icons/navigation";
import PercentageButton from "app/components/percentageButton";
import Balance from "app/components/balance";
import IconButton from "app/components/iconButton";
import { Currency, geDisplayValue, geDisplayValueInCrypto, isFiat } from "app/models/rate";
import { useStore } from "app/stores/store";
import { Asset } from "app/models/asset";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ExchangeFormValues } from "app/models/exchange";
import TFAVerification from "features/users/TFAVerification";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import { history } from "../..";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
    showSummary: boolean;
    handleContinue: () => void;
    handleSell: () => void;
}

export default observer(function Trade({ showSummary, handleSell, handleContinue, ...rest }: Props) {
    const {
        modalStore,
        profileStore: { loadProfile, loadingProfile, getAssets, createCryptoAccount },
        krakenStore: { getRate },
        userStore: { user },
        exchangeStore: { loading, createExchange },
    } = useStore();
    const [error, setError] = useState(false);
    const [displayFiat, setDisplayFiat] = useState(true);
    const [amount, setAmount] = useState(0);
    // const [toAmount, setToAmount] = useState(0);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [fromAsset, setFromAsset] = useState(new Asset({ currency: Currency.EUR, balance: 0 }));
    const [toAsset, setToAsset] = useState(new Asset({ currency: Currency.BTC, balance: 0 }));
    const { t } = useTranslation();

    useEffect(() => {
        if (user) {
            loadProfile(user.username);
        }
    }, [loadProfile, user]);

    function setAllToAmounts(amount: number) {
        setSelectedAmount(amount);
        setAmount(amount);
    }

    function handleSelectAmount(part: number) {
        var decimals = isFiat(fromAsset.currency) ? 2 : 8;
        var amount = !!fromAsset?.balance ? parseFloat((fromAsset?.balance * part).toFixed(decimals)) : 0;
        setAllToAmounts(amount);
        // setSelectedAmount(amount);
        setError(false);
    }

    function handleSwitch() {
        // setSelectedAmount(0);
        setAllToAmounts(0);
        var tmp = fromAsset;
        setFromAsset(toAsset);
        setToAsset(tmp);
    }

    function handleSubmit() {
        var values = new ExchangeFormValues();
        values.fromCurrency = fromAsset.currency;
        values.toCurrency = toAsset.currency;
        values.amountFrom = amount;

        if (user?.tfaGoogleEnabled) modalStore.openModal(<TFAVerification callback={submitExchange} params={values} />);
        else submitExchange(values);
    }

    async function submitExchange(values: ExchangeFormValues) {
        await createExchange(values);
        if (user) await loadProfile(user.username);
        setAllToAmounts(0);
        // setSelectedAmount(0);
        handleSell();
    }

    function getToAmount() {
        var decimals = isFiat(toAsset.currency) ? 2 : 8;
        return !!amount ? parseFloat((amount * getRate(fromAsset.currency, toAsset.currency)!.value).toFixed(decimals)) : 0;
    }

    function getDisplayToAmount() {
        var decimals = isFiat(toAsset.currency) ? 2 : 8;
        return getToAmount().toFixed(decimals);
    }

    return (
        <Flex
            bg="gray.800"
            borderRadius="0.75rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            p={{ base: "4", md: "10" }}
            direction="column"
            color="white"
            {...rest}
        >
            {loadingProfile || loading ? (
                <Spinner alignContent={"center"} color="yellow.500" size="xl" />
            ) : showSummary ? (
                <Flex direction="column">
                    <Heading size="h3_bold" mb="6">
                        + {getDisplayToAmount()} {toAsset.currency}
                    </Heading>
                    <Flex justify="space-between" mb="6">
                        <Text size="smallText_normal" color="gray.300">
                            {t("asset.from")}
                        </Text>
                        <Flex align="center">
                            <Image w="24px" h="24px" src={"/coins/" + fromAsset.currency.toLowerCase() + ".svg"} />
                            <Text ml="3" size="smallText_semibold">
                                {fromAsset.currency}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justify="space-between" mb="6">
                        <Text size="smallText_normal" color="gray.300">
                        {t("asset.to")}
                        </Text>
                        <Flex align="center">
                            <Image w="24px" h="24px" src={"/coins/" + toAsset.currency.toLowerCase() + ".svg"} />
                            <Text ml="3" size="smallText_semibold">
                                {toAsset.currency}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justify="space-between" mb="6">
                        <Text size="smallText_normal" color="gray.300">
                            {t("exchange.rate")}
                        </Text>
                        <Flex align="flex-end">
                            <Text size="smallText_semibold" textAlign="right">
                                {geDisplayValue(getRate(fromAsset.currency, toAsset.currency)!, true)}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justify="space-between" mb="6">
                        <Text size="smallText_normal" color="gray.300">
                            {t("prohash.fee")}
                        </Text>
                        <Flex align="center">
                            <Text size="smallText_semibold" textAlign="right">
                                0
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justify="space-between" mb="10">
                        <Text size="smallText_normal" color="gray.300">
                            Total
                        </Text>
                        <Flex align="flex-end" direction="column">
                            <Text size="smallText_semibold" textAlign="right">
                                + {getDisplayToAmount()} {toAsset.currency}
                            </Text>
                            <Text size="smallText_semibold" color="gray.500" textAlign="right">
                                - {amount.toFixed(isFiat(fromAsset.currency) ? 2 : 8)} {fromAsset.currency}
                            </Text>
                        </Flex>
                    </Flex>
                    <Button variant="primary" onClick={handleSubmit} isLoading={loading} isDisabled={loading}>
                        {t("trade.now")}
                    </Button>
                </Flex>
            ) : (
                <>
                    <Flex
                        align={{
                            base: "flex-start",
                            sm: "center",
                            md: "flex-start",
                            xl: "center",
                        }}
                        justify="space-between"
                        direction={{ base: "column", sm: "row", md: "column", xl: "row" }}
                        mb={{ base: "4", xl: "6" }}
                    >
                        <Heading size="h4_bold" mb={{ base: "3", sm: "0", md: "3", xl: "0" }}>
                            {t("buy")} {toAsset.currency} {t("with")} {fromAsset.currency}
                        </Heading>
                        <Text size="smallText_semibold" d="flex" alignItems="center">
                            {displayFiat
                                ? geDisplayValue(getRate(fromAsset.currency, toAsset.currency)!, true)
                                : geDisplayValueInCrypto(getRate(fromAsset.currency, toAsset.currency)!, true)}
                            <DepositWithdraw
                                ml="2"
                                w="16px"
                                h="16px"
                                color="gray.500"
                                cursor="pointer"
                                onClick={() => setDisplayFiat(!displayFiat)}
                            />
                        </Text>
                    </Flex>
                    <Flex mb="6">
                        <PercentageButton variant="secondary" onClick={() => handleSelectAmount(1)}>
                            {t("all")}
                        </PercentageButton>
                        <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.75)}>
                            75%
                        </PercentageButton>
                        <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.5)}>
                            50%
                        </PercentageButton>
                        <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.25)}>
                            25%
                        </PercentageButton>
                    </Flex>
                    <Flex direction="column" align="center" mb="10">
                        <Balance
                            variant="from"
                            mb="2"
                            hasError={error}
                            isDebit={true}
                            setError={setError}
                            setAmount={setAllToAmounts}
                            hasInput={true}
                            assets={getAssets(isFiat(fromAsset.currency))}
                            setAsset={setFromAsset}
                            asset={fromAsset}
                            selectedAmount={selectedAmount}
                        />
                        <IconButton
                            variant="primary"
                            aria-label="switch trade"
                            my="-24px"
                            borderWidth="4px"
                            borderStyle="solid"
                            borderColor="gray.700"
                            icon={<TradeIcon w="20px" h="20px" color="black" />}
                            onClick={handleSwitch}
                        />
                        <Balance
                            variant="to"
                            // setAmount={setToAmount}
                            hasInput={true}
                            isInputDisabled={true}
                            assets={getAssets(isFiat(toAsset.currency))}
                            setAsset={setToAsset}
                            asset={toAsset}
                            selectedAmount={getToAmount()}
                        />
                    </Flex>
                    {toAsset.accountId && fromAsset.accountId ? (
                        <Button variant="primary" onClick={handleContinue} isDisabled={error || amount === 0 || getToAmount() == 0}>
                            {t("continue.bttn")}
                        </Button>
                    ) : (
                        <>
                            {!fromAsset.accountId &&
                                (!isFiat(fromAsset.currency) ? (
                                    <>
                                        <Notification
                                            messageStyle={{ fontWeight: "normal" }}
                                            message={t("invalid.acc.message.Withdraw")}
                                            type={MessageType.Error}
                                            bg="gray.700"
                                            mb="10"
                                        />
                                        <Button
                                            variant="primary"
                                            w="100%"
                                            mb="4"
                                            onClick={() => createCryptoAccount(fromAsset.currency)}
                                            isLoading={loading}
                                        >
                                            {t("activate.crypto.acc.bttn")}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Notification
                                            messageStyle={{ fontWeight: "normal" }}
                                            message={t("invalid.acc.message")}
                                            type={MessageType.Error}
                                            bg="gray.700"
                                            mb="10"
                                        />
                                        <Button variant="primary" mb="4" w="100%" onClick={() => history.push("/profiles/settings/3")}>
                                            {t("add.bank.acc.btn")}
                                        </Button>
                                    </>
                                ))}
                            {!toAsset.accountId && (!isFiat(toAsset.currency) ? (
                                <>
                                    <Notification
                                        messageStyle={{ fontWeight: "normal" }}
                                        message={t("invalid.acc.message.Withdraw")}
                                        type={MessageType.Error}
                                        bg="gray.700"
                                        mb="10"
                                    />
                                    <Button variant="primary" w="100%" onClick={() => createCryptoAccount(toAsset.currency)} isLoading={loading}>
                                    {t("activate.crypto.acc.bttn")}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Notification
                                        messageStyle={{ fontWeight: "normal" }}
                                        message={t("invalid.acc.message")}
                                        type={MessageType.Error}
                                        bg="gray.700"
                                        mb="10"
                                    />
                                    <Button variant="primary" w="100%" onClick={() => history.push("/profiles/settings/3")}>
                                        {t("add.bank.acc.btn")}
                                    </Button>
                                </>
                            ))}
                        </>
                    )}
                </>
            )}
        </Flex>
    );
});
