import * as React from "react";
import { FlexProps, Flex, Button, Image, Text, useToast, useClipboard } from "@chakra-ui/react";
import { Toast } from "app/components/toast";
import { Copy, Loader } from "assets/icons/home";
import { Currency } from "app/models/rate";
import { EmptyCheck } from "assets/icons/onboarding";
import { useStore } from "app/stores/store";
import { WhitelistedAddress } from "app/models/cryptoAccount";
import { useTranslation } from "react-i18next";

interface AccountProps extends FlexProps {
    address: WhitelistedAddress;
    currency: Currency;
}

export function ProfileWhitelistedAddressCard({ address, currency, ...rest }: AccountProps) {
    const {
        profileStore: { deleteWhitelistedAddress, isCurrentUser },
    } = useStore();
    const toast = useToast();
    const { hasCopied, onCopy } = useClipboard(address.value);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (hasCopied) {
            toast({
                position: "bottom",
                duration: 1000,
                render: () => <Toast icon={<Copy w="20px" h="20px" color="yellow.800" />} message={t("address.copied.clipboard")} />,
            });
        }
    }, [hasCopied, toast]);

    return (
        <Flex
            p={{ base: "4", sm: "6" }}
            borderRadius="0.75rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            bg="gray.800"
            align={{ base: "flex-start", md: "center" }}
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            {...rest}
        >
            <Flex
                basis="50%"
                justify="space-between"
                align={{ base: "flex-start", sm: "center" }}
                direction={{ base: "column", sm: "row" }}
                sx={{ gap: "12px" }}
                mb={{ base: "4", md: "0" }}
            >
                <Flex align="center" mb={{ base: "0", sm: "0" }}>
                    <Image src={"/coins/" + currency.toLowerCase() + ".svg"} alt="" minW="40px" minH="40px" h="0" w="0" mr="4" />
                    <Flex direction="column" minW="0">
                        <Text size="paragraph_bold" color="white" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="15em">
                            {address.label}
                        </Text>
                        <Text size="smallText_normal" color="gray.500" overflow="hidden" textOverflow="ellipsis" maxWidth="17em">
                            {address.value}
                        </Text>
                    </Flex>
                </Flex>
                <Flex align="center">
                    {address.verified ? (
                        <>
                            <EmptyCheck w="20px" h="20px" color="green.500" />
                            <Text ml="3" size="caption_bold">
                                {t("verified")}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Loader w="20px" h="20px" color="yellow.800" />
                            <Text ml="3" size="caption_bold">
                                {t("verifying")}
                            </Text>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex align="center" justify="center" direction="row" w={{ base: "100%", md: "min-content" }}>
                {isCurrentUser && (
                    <Button
                        variant="secondary"
                        onClick={() => deleteWhitelistedAddress(address.id, currency)}
                        mr="3"
                        w={{ base: "100%", md: "min-content" }}
                    >
                        {t("remove")}
                    </Button>
                )}
                <Button variant="primary" onClick={onCopy} w={{ base: "100%", md: "min-content" }}>
                    {t("copy")}
                </Button>
            </Flex>
        </Flex>
    );
}
