import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Button, Text, Image } from "@chakra-ui/react";
import CreatedImage from "assets/images/account/created.webp";
import { history } from "../..";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import { runInAction } from "mobx";
import { useTranslation } from "react-i18next";

export default function Success() {
    const { profileStore, userStore } = useStore();
    const { t } = useTranslation();

    useEffect(() => {
        // if (userStore.user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("end");
    }, [profileStore, userStore]);

    return (
        <>
            <OnboardingSteps steps={5} currentStep={6} />
            <AuthenticationTemplate headingText= {t("all.done")} pt={{ base: 20, md: 0 }}>
                <Image mx="auto" w="172px" h="173px" mb="10" src={CreatedImage} />
                <Text mb="10" size="paragraph_semibold" color="gray.400" textAlign="center">
                    {t("acc.verify.message")}
                </Text>
                <Button
                    variant="primary"
                    onClick={() => {
                        runInAction(() => {
                            userStore.user!.onboardingDone = true;
                        });
                        history.push("/home");
                    }}
                >
                    {t("dashboard.bttn")}
                </Button>
            </AuthenticationTemplate>
        </>
    );
}
