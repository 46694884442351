import * as React from "react";
// import { Intro, Rates, Security, Action, Steps, Questions, Call } from "features/landing";
import { history } from "../..";
import UnauthenticatedNavigation from "app/components/unauthenticatedNavigation";
import { useStore } from "app/stores/store";
import Footer from "app/layout/Footer";

import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Heading, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { getAccountOperationCategoryLabel } from "app/models/transaction";

export default function AML() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();

    const headers = [
        "AML.p18",
        "AML.p28",
        "AML.p33",
        "AML.p36",
        "AML.p39",
        "AML.p73",
        "AML.p86",
        "AML.p92",
        "AML.p100",
        "AML.p109",
        "AML.p120",
        "AML.p124",
        "AML.p129",
        "AML.p138",
        "AML.p142",
        "AML.p147",
    ];

    const getContent = () => {
        const rows = [];
        for (let i = 2; i <= 148; i++) {
            if (headers.includes(`AML.p${i}`)) {
                rows.push(
                    <Heading size="h4_bold" textAlign="left" mb="5" mt="12" >
                        {t(`AML.p${i}`)}
                    </Heading>
                );
            } else {
                rows.push(
                    <Text key={i} size="paragraph_normal" textAlign="left"  mb="3">
                        {t(`AML.p${i}`)}
                    </Text>
                );
            }
        }
        return rows;
    };

    return (
        <Grid color="white" mt={"3em"} height="90%" px="1">
            <Flex direction="column" align="start" textAlign="left" maxW="container.xl" mx="auto" px="6">
                <Heading size={headerSize} mb="2">
                    {t("AML.p1")}
                </Heading>

                <Flex
                    direction="column"
                    align="start"
                    textAlign="left"
                    mx="auto"
                    px="6"
                    py="10"
                    bg="gray.800"
                    borderRadius="0.75rem"
                    borderStyle="solid"
                    borderWidth="1px"
                    borderColor="gray.700"
                >
                    {getContent()}
                    
                </Flex>
            </Flex>
        </Grid>
    );
}
