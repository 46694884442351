import * as React from "react";
import {
  FlexProps,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { Copy } from "assets/icons/home";
import {Toast} from "../toast";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
  label?: string;
  value: string;
}

export default function ReadOnlyField({ label, value, ...rest }: Props) {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();
  React.useEffect(() => {
    if (hasCopied) {
      toast({
        position: "bottom",
        duration: 1000,
        render: () => (
          <Toast
            icon={<Copy w="20px" h="20px" color="yellow.800" />}
            message={label + " " + t("copied.clipboard")}
          />
        ),
      });
    }
  }, [hasCopied, toast, label]);
  return (
    <Flex direction="column" {...rest}>
      <Text size="paragraph_normal" color="gray.400" mb="2">
        {label}
      </Text>
      <InputGroup onClick={onCopy}>
        <Input
          variant="flushed"
          readOnly
          value={value}
          minH="72px"
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          paddingRight="14"
        />
        <InputRightElement
          bottom="0"
          right="6"
          my="auto"
          justifyContent="flex-end"
          cursor={hasCopied ? "default" : "pointer"}
          onClick={onCopy}
        >
          <Copy
            w="20px"
            h="20px"
            color={hasCopied ? "gray.700" : "yellow.800"}
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
}
